import { Box, Flex, Heading } from '@chakra-ui/react';
import type { ContainerProps, IconProps } from '@chakra-ui/react';
import { FC } from 'react';

interface IAboutCardProps extends ContainerProps {
  Icon: FC<IconProps>;
  heading: string;
  description: string;
}

const FeatureCard: FC<IAboutCardProps> = ({ Icon, heading, description, ...props }) => (
  <Flex
    key={heading}
    alignItems={{ base: 'center', md: 'start' }}
    flexDir='column'
    gap='md'
    textAlign={{ base: 'center', md: 'start' }}
    w={{ base: 'full', sm: '60%', md: '45%' }}
    {...props}
  >
    {Icon && (
      <Box
        borderRadius='full'
        p={{ base: 'xs', md: 'sm' }}
        shadow='md'
        w='fit-content'
      >
        <Icon
          boxSize={{ base: 8, md: 10 }}
          color='icon.pure'
          strokeWidth='1.5'
        />
      </Box>
    )}
    <Heading
      as='h3'
      size='md'
    >
      {heading}
    </Heading>
    {description}
  </Flex>
);

export default FeatureCard;
