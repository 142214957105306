import { Flex, Heading } from '@chakra-ui/react';
import { DefaultContainer } from '@/shared/ui';
import { whoIsThisForList } from '../lib/whoIsThisForList';
import FeatureCard from './FeatureCard';

const WhoIsThisForSection = () => {
  return (
    <DefaultContainer
      gap='2xl'
      id='who-is-this-for'
    >
      <Heading textAlign='center'>Who Is This For?</Heading>
      <Flex
        flexWrap='wrap'
        gap='xl'
        justifyContent='space-around'
      >
        {whoIsThisForList.map((data) => (
          <FeatureCard
            key={data.heading}
            alignItems='center'
            textAlign='center'
            w={{ base: '80%', sm: '45%', md: '25%' }}
            {...data}
          />
        ))}
      </Flex>
    </DefaultContainer>
  );
};

export default WhoIsThisForSection;
