import { Box, Flex, Heading, List, ListIcon, ListItem } from '@chakra-ui/react';
import { CheckIcon, DefaultContainer } from '@/shared/ui';
import { videoBlocksData } from '../lib/videoBlocksData';

const VideoBlock = () => (
  <>
    {videoBlocksData.map(({ title, steps, videoUrl }, index) => {
      const isOdd = index % 2 === 0;
      return (
        <DefaultContainer
          key={title}
          alignItems='center'
          bg={!isOdd ? 'bg.pure' : 'bg.light'}
          flexDir={{ base: 'column', md: !isOdd ? 'row' : 'row-reverse' }}
          gap='3xl'
          justifyContent={{ base: 'center', md: 'space-between' }}
          mb={!isOdd ? 'xl' : 0}
        >
          <Flex
            alignItems={{ base: 'center', md: 'start' }}
            flexDir='column'
            gap='xl'
            w={{ base: 'full', md: '50%' }}
          >
            <Heading whiteSpace={{ base: 'normal', lg: 'pre-wrap' }}>{title}</Heading>
            <List
              display='flex'
              flexDir='column'
              gap='xs'
            >
              {steps.map((text) => (
                <ListItem
                  key={text}
                  fontSize='lg'
                >
                  <ListIcon
                    as={CheckIcon}
                    color='green.500'
                  />
                  {text}
                </ListItem>
              ))}
            </List>
          </Flex>
          <Box
            borderRadius='xl'
            h={{ base: 300, lg: 540 }}
            overflow='hidden'
            w={{ base: 300, lg: 537 }}
          >
            <iframe
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
              allowFullScreen
              height='100%'
              referrerPolicy='strict-origin-when-cross-origin'
              src={`https://www.youtube.com/embed/${videoUrl}`}
              title='YouTube video player'
              width='100%'
            />
          </Box>
        </DefaultContainer>
      );
    })}
  </>
);

export default VideoBlock;
