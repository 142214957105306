import { AboutComponent } from '@/widgets/AboutComponent';
import { FAQ } from '@/widgets/FAQ';
import { RedirectToAppButton } from '@/widgets/RedirectToAppButton';
import { headerHeight, metaData } from '@/shared/constants';
import { JsonLdDecorator, MetaDecorator } from '@/shared/utils';
import { listItemsJsonLd } from '../lib/listItemsJsonLd';
import { questions } from '../lib/questions';
import AutomationBenefits from './AutomationBenefits';
import GetStartedBlock from './GetStartedBlock';
import NewCustomerForm from './NewCustomerForm';
import ReviewsRequestsBlock from './ReviewsRequestsBlock';
import VideoBlock from './VideoBlock';
import WhoIsThisForSection from './WhoIsThisForSection';
import main1 from '/public/main-page/main-1.webp';
import main2 from '/public/main-page/main-2.webp';

const HEADING_TEXT = `Automatically Get More Reviews\nOutrank Competitors\nWin More Clients`;
const DESCRIPTION_TEXT =
  'LocalProf is a powerful reputation growth tool for small business owners. It automates reviews and referrals, making it easier than ever to attract more customers, all at an unbeatable price';

const MATTER_TEXT =
  'Did you know that 82% of consumers read online reviews before choosing a local business, and a 1-star rating increase can boost revenue by up to 9%? Plus, 93% of customers say online reviews influence their purchase decisions';

const MainPage = () => {
  return (
    <>
      <MetaDecorator
        description={metaData.main.description}
        keywords={metaData.main.keywords}
        title={metaData.main.title}
      />
      <JsonLdDecorator
        faqList={questions}
        listItems={listItemsJsonLd}
      />

      <AboutComponent
        button={
          <RedirectToAppButton
            buttonText='Start free trial'
            dataLayer={{ data: { redirected_from: 'main_page_main_block' } }}
            pageName='signup'
            size='md'
          />
        }
        containerProps={{ gap: '20', mt: headerHeight }}
        description={DESCRIPTION_TEXT}
        heading={HEADING_TEXT}
        headingAs='h1'
        headingProps={{
          whiteSpace: 'pre-wrap',
        }}
        image={main1}
        imageProps={{ isEager: true }}
      />

      <AboutComponent
        containerHeading={`Why Reviews & Automation\nMatter`}
        containerProps={{ gap: '20' }}
        description={MATTER_TEXT}
        descriptionStyles={{
          w: { base: 'full', md: '60%' },
          ml: { base: 0, lg: '2xl' },
        }}
        image={main2}
        isOdd={true}
      />
      <AutomationBenefits />
      <ReviewsRequestsBlock />
      <WhoIsThisForSection />
      <VideoBlock />
      <NewCustomerForm />
      <FAQ questions={questions} />
      <GetStartedBlock />
    </>
  );
};

export default MainPage;
