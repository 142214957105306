import { gql } from '@urql/core';

export const CREATE_APP_LEAD = gql`
  mutation createAppLead($input: CreateAppLeadInput) {
    createAppLead(input: $input) {
      email
      name
      phone
      companyName
      description
      utmContent
      utmCampaign
      utmSource
      utmTerm
      gclid
      integrations
      locationsCount
      reviewPlatforms
      monthlyCustomers
      reviewManagers
      interestedSolutions
    }
  }
`;
