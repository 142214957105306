import type { ButtonProps, CheckboxProps } from '@chakra-ui/react';

export type TOptionsType = 'single' | 'multiple';

export interface IQuizStepData {
  heading: string;
  options: {
    name: string;
    items: { label: string; value: string }[];
    type: TOptionsType;
    styles?: CheckboxProps;
  };
  input?: {
    name: string;
    placeholder: string;
    requiredAnswer?: string;
  };
  buttonText?: string;
  buttonType?: ButtonProps['type'];
}

export const quizStepsData: IQuizStepData[] = [
  {
    heading: 'Which of the following solutions are you interested in?',
    options: {
      name: 'interestedSolutions',
      items: [
        { label: 'Centralized review management', value: 'Centralized review management' },
        { label: 'Review analytics and insights', value: 'Review analytics and insights' },
        {
          label: 'Referral/loyalty program automation',
          value: 'Referral/loyalty program automation',
        },
        { label: 'SMS/Text message marketing', value: 'SMS/Text message marketing' },
        { label: 'Customer surveys (NPS)', value: 'Customer surveys (NPS)' },
        { label: 'Listings management', value: 'Listings management' },
        { label: 'Competitor review monitoring', value: 'Competitor review monitoring' },
        { label: 'Negative review alerts', value: 'Negative review alerts' },
      ],
      type: 'multiple',
    },
    input: {
      name: 'otherInterestedSolutions',
      placeholder: 'Please specify your main goal or preference',
    },
  },
  {
    heading: `How many business locations do you manage?`,
    options: {
      name: 'locationsCount',
      items: [
        { label: '1', value: '1' },
        { label: '2 - 5', value: '2-5' },
        { label: '6 - 10', value: '6-10' },
        { label: '11+', value: '11+' },
      ],
      type: 'single',
    },
  },
  {
    heading: `Approximately how many customers do you serve monthly?`,
    options: {
      name: 'monthlyCustomers',
      items: [
        { label: 'Less than 50', value: '<50' },
        { label: '50-200', value: '50-200' },
        { label: '200-500', value: '200-500' },
        { label: '500+', value: '500+' },
      ],
      type: 'single',
    },
  },

  {
    heading: `Which review platforms do you currently have profiles on?`,
    options: {
      name: 'reviewPlatforms',
      items: [
        { label: 'Google', value: 'Google' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'Yelp', value: 'Yelp' },
        { label: 'Bing', value: 'Bing' },
        { label: 'Trustpilot', value: 'Trustpilot' },
        { label: 'Nextdoor', value: 'Nextdoor' },
        { label: 'HomeAdvisor', value: 'HomeAdvisor' },
        { label: 'Better Business Bureau (BBB)', value: 'BBB' },
      ],
      type: 'multiple',
      styles: {
        w: '46%',
        flexGrow: 1,
      },
    },
    input: {
      name: 'otherReviewPlatforms',
      placeholder: 'Specify other review platforms you use',
    },
  },

  {
    heading: `What tools or software do you currently use to manage your business?`,
    options: {
      name: 'crmPlatforms',
      items: [
        { label: 'HubSpot', value: 'HubSpot' },
        { label: 'QuickBooks', value: 'QuickBooks' },
        { label: 'Monday.com', value: 'Monday' },
        { label: 'Zoho', value: 'Zoho' },
        { label: 'Stripe', value: 'Stripe' },
        { label: 'FreshBooks', value: 'FreshBooks' },
        { label: 'Square', value: 'Square' },
        { label: 'Housecall Pro', value: 'Housecall Pro' },
        { label: 'Jobber', value: 'Jobber' },
        { label: 'ServiceTitan', value: 'ServiceTitan' },
        { label: 'Mailchimp', value: 'Mailchimp' },
        { label: 'FieldPulse', value: 'FieldPulse' },
        { label: 'Tradify', value: 'Tradify' },
      ],
      type: 'multiple',
      styles: {
        w: '46%',
      },
    },
    input: {
      name: 'otherCrmPlatforms',
      placeholder: 'Specify other CRM platforms you use',
    },
  },

  {
    heading: 'Do you currently use any review management platform or software?',
    options: {
      name: 'isUseReviewManagers',
      items: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' },
      ],
      type: 'single',
    },
    input: {
      name: 'otherReviewManagers',
      placeholder: 'Please specify reviews managers you use',
      requiredAnswer: 'true',
    },
    buttonText: 'See result',
    buttonType: 'submit',
  },
];
