import { Heading, Highlight } from '@chakra-ui/react';
import { Form, pushToDataLayer } from '@repo/shared/shared';
import { useForm } from 'react-hook-form';
import { useGlobalState } from '@/shared/providers';
import { DefaultContainer, IStepComponentProps, Slider } from '@/shared/ui';
import { localStorage } from '@/shared/utils';
import { IQuizForm } from '../lib/generateQuizResponse';
import { quizStepsData } from '../lib/quizStepsData';
import FinalQuizStep from './FinalQuizStep';
import QuizFormStep from './QuizStepForm';

const steps = quizStepsData.map(
  (data) =>
    function StepItem({ ...props }: IStepComponentProps) {
      return (
        <QuizFormStep
          {...data}
          {...props}
        />
      );
    },
);

steps.push(function StepItem() {
  return <FinalQuizStep />;
});

const NewCustomerForm = () => {
  const { setQuizResult } = useGlobalState();

  const methods = useForm<IQuizForm>({
    defaultValues: {
      locationsCount: '',
      monthlyCustomers: '',
      reviewPlatforms: [],
      otherReviewPlatforms: '',
      crmPlatforms: [],
      otherCrmPlatforms: '',
      interestedSolutions: [],
      otherInterestedSolutions: '',
      isUseReviewManagers: '',
      otherReviewManagers: '',
    },
  });

  const onSubmit = (formData: IQuizForm) => {
    const stringifiedQuiz = JSON.stringify({
      ...formData,
      timeStamp: Math.floor(Date.now() / 1000),
    });
    localStorage.setItem('quiz', stringifiedQuiz);
    setQuizResult(stringifiedQuiz);
    pushToDataLayer('new_customer_form_sended');
  };

  return (
    <DefaultContainer
      alignItems='center'
      bg={{ base: 'bg.light', md: 'bg.pure' }}
      gap='xl'
      py='0'
    >
      <Form
        childrenContainerProps={{
          gap: 'xl',
          textAlign: 'center',
          alignItems: 'center',
          bg: 'bg.light',
          py: { base: 10, md: 20 },
          px: { base: 0, md: 'xl' },
          borderRadius: 'xl',
          w: 'full',
        }}
        formId='newCustomerForm'
        methods={methods}
        onSubmit={onSubmit}
      >
        <Heading
          size='xl'
          whiteSpace='pre-wrap'
        >
          <Highlight
            query={['Wondering']}
            styles={{ color: 'primary.pure' }}
          >
            {methods.formState.isSubmitted
              ? 'We’re sure we’re the perfect fit for your business!'
              : `Wondering if we're the right fit?\nTake a quick quiz and get your answer right here!`}
          </Highlight>
        </Heading>
        <Slider steps={steps} />
      </Form>
    </DefaultContainer>
  );
};

export default NewCustomerForm;
