export const reviewsRequestsCards = [
  {
    heading: 'Easy Campaign Setup',
    steps: ['Customize email/SMS templates', 'Schedule automated follow-ups'],
  },
  {
    heading: 'Pre-Review Surveys',
    steps: ['Gather private feedback', 'Gain deeper customer insights'],
  },
  {
    heading: 'Choose Your Review Sites',
    steps: ['Drive reviews to Google, Facebook, etc.', 'Focus on platforms that matter most'],
  },
  {
    heading: 'CRM Integration',
    steps: ['Sync contact data automatically', 'Trigger requests without manual effort'],
  },
];
