import { BoltIcon, CometIcon, GrowthIcon, RocketIcon } from '@/shared/ui';

export const automationBenefits = [
  {
    Icon: CometIcon,
    heading: 'Get 3× more reviews, faster',
    description:
      'Automating your outreach ensures every satisfied customer is encouraged to leave feedback. More reviews = more credibility = more customers',
  },
  {
    Icon: RocketIcon,
    heading: 'Boost referrals effortlessly',
    description:
      'Trigger referral invites as soon as a customer leaves a positive review, turning your happy clients into ambassadors for your business',
  },
  {
    Icon: GrowthIcon,
    heading: 'Increase conversion rates',
    description:
      'Showcase fresh, high-quality reviews and watch your online conversions climb. Customers are more likely to trust and choose a business with recent positive feedback',
  },
  {
    Icon: BoltIcon,
    heading: 'Stand out in search results',
    description:
      'Consistent review activity signals search engines that your business is active and trustworthy, improving local SEO and online visibility',
  },
];
