import { Flex, Text } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { RedirectToAppButton } from '@/widgets/RedirectToAppButton';
import generateQuizResponse from '../lib/generateQuizResponse';

const FinalQuizStep = () => {
  const { getValues } = useFormContext();

  const TEXT = generateQuizResponse(getValues());

  return (
    <Flex
      alignSelf='center'
      flexDir='column'
      gap='xl'
      justifyContent='center'
      w={{ base: 'full', lg: 'container.lg' }}
    >
      <Text
        fontSize='lg'
        whiteSpace='pre-wrap'
      >
        {TEXT}
      </Text>
      <RedirectToAppButton
        alignSelf='center'
        buttonText='Start 14 days free trial'
        dataLayer={{ event: 'quiz_start_trial' }}
        pageName='signup'
        size='md'
        w='3xs'
      />
    </Flex>
  );
};

export default FinalQuizStep;
