import { automationBenefits } from './automationBenefits';
import { whoIsThisForList } from './whoIsThisForList';

export const listItemsJsonLd = [
  {
    heading: 'How our automation solves these challenges',
    list: automationBenefits,
  },
  {
    heading: 'Who Is This For?',
    list: whoIsThisForList,
  },
];
