import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Heading,
} from '@chakra-ui/react';
import { FC } from 'react';
import { DefaultContainer } from '@/shared/ui';
import { IFAQ } from '../type/faqType';

interface IFAQProps {
  questions: IFAQ[];
}

const FAQ: FC<IFAQProps> = ({ questions }) => (
  <DefaultContainer gap={{ base: 'sm', md: 'lg' }}>
    <Heading
      fontWeight='medium'
      size={{ base: 'xl', md: '2xl' }}
      textAlign='center'
    >
      Frequently asked questions
    </Heading>
    <Accordion allowMultiple>
      {questions?.map(({ question, answer }) => (
        <AccordionItem
          key={question}
          borderBottom='1px'
          borderColor='border.light'
          borderTop={0}
        >
          <AccordionButton
            fontSize='xl'
            fontWeight='semibold'
            justifyContent='space-between'
            px='0'
            py='md'
            textAlign='left'
          >
            {question}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel whiteSpace='pre-line'>{answer}</AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  </DefaultContainer>
);

export default FAQ;
