import { Link } from '@chakra-ui/next-js';
import { Button, Flex, Heading, Text } from '@chakra-ui/react';
import type { ContainerProps, HeadingProps, TextProps } from '@chakra-ui/react';
import { StaticImageData } from 'next/image';
import { FC, ReactNode } from 'react';
import { CardsBlock } from '@/widgets/CardsBlock';
import { HeadingAs } from '@/shared/types';
import { DefaultContainer, Image, PointIcon } from '@/shared/ui';
import { ImageProps } from 'src/shared/ui/Image';

interface IBenefit {
  title: string;
  description: string;
}
interface IAboutComponentProps extends ContainerProps {
  smallText?: string;
  heading?: string;
  headingProps?: HeadingProps;
  description: string;
  image: StaticImageData;
  buttonText?: string;
  href?: string;
  containerProps?: ContainerProps;
  headingAs?: HeadingAs;
  benefits?: IBenefit[];
  benefitsCards?: any;
  isOdd?: boolean;
  button?: ReactNode;
  containerHeading?: string;
  descriptionStyles?: TextProps;
  imageProps?: Partial<ImageProps>;
}

interface IImgProps extends Partial<ImageProps> {
  alt: string;
  image: StaticImageData;
}

const Img: FC<IImgProps> = ({ alt, image, ...rest }) => (
  <Image
    alt={alt}
    aspectRatio={image.width / image.height}
    borderRadius='xl'
    height='auto'
    imgSizes={{
      base: '100%',
      md: '40rem',
    }}
    objectFit='contain'
    overflow='hidden'
    src={image}
    width={{ base: 'full', md: '24rem', lg: '32rem' }}
    {...rest}
  />
);

const AboutComponent: FC<IAboutComponentProps> = ({
  smallText,
  heading,
  description,
  image,
  buttonText,
  containerProps,
  benefits,
  headingAs = 'h2',
  href,
  benefitsCards,
  isOdd = false,
  button,
  headingProps,
  containerHeading,
  descriptionStyles,
  imageProps,
  ...rest
}) => {
  const baseTemplate = containerHeading
    ? `"head" "text" "image" "cards"`
    : `"text" "image" "cards"`;
  const lgTemplateOdd = containerHeading
    ? `"head head" "image text" "cards cards"`
    : `"image text" "cards cards"`;
  const lgTemplateEven = containerHeading
    ? `"head head" "text image" "cards cards"`
    : `"text image" "cards cards"`;

  const gridTemplateAreas = {
    base: baseTemplate,
    lg: isOdd ? lgTemplateOdd : lgTemplateEven,
  };

  return (
    <DefaultContainer
      alignItems='center'
      bg={isOdd ? 'bg.light' : 'bg.pure'}
      containerProps={containerProps}
      display='grid'
      gap='md'
      gridAutoColumns='1fr'
      gridTemplateAreas={gridTemplateAreas}
      justifyItems='center'
      {...rest}
    >
      {containerHeading && (
        <Heading
          gridArea='head'
          lineHeight='normal'
          mb='md'
          textAlign='center'
          whiteSpace='pre-wrap'
        >
          {containerHeading}
        </Heading>
      )}
      <Flex
        alignItems={{ base: 'center', lg: 'start' }}
        flexDir='column'
        gap='xl'
        gridArea='text'
        justifyContent='center'
      >
        {smallText && (
          <Text
            color='text.light'
            fontWeight='semibold'
          >
            {smallText}
          </Text>
        )}
        {heading && (
          <Heading
            as={headingAs}
            lineHeight='normal'
            textAlign={{ base: 'center', lg: 'left' }}
            {...headingProps}
          >
            {heading}
          </Heading>
        )}

        <Img
          alt={heading || ''}
          display={{ base: 'block', lg: 'none' }}
          image={image}
          {...imageProps}
        />
        <Text
          fontSize='lg'
          textAlign={{ base: 'center', lg: 'start' }}
          {...descriptionStyles}
        >
          {description}
        </Text>
        {benefits && (
          <Flex
            flexDir='column'
            gap='sm'
          >
            {benefits.map(({ title, description }) => (
              <Flex
                key={title}
                flexDir='column'
              >
                <Flex
                  alignItems='center'
                  color='primary.pure'
                >
                  <PointIcon boxSize={4} />
                  <Heading
                    as='h4'
                    fontSize='md'
                  >
                    {title}
                  </Heading>
                </Flex>
                <Text
                  fontWeight='medium'
                  ml='sm'
                >
                  {description}
                </Text>
              </Flex>
            ))}
          </Flex>
        )}
        {button && button}
        {buttonText && (
          <Button
            alignSelf='start'
            as={Link}
            href={href}
            w={{ base: 'full', md: 'fit-content' }}
          >
            {buttonText}
          </Button>
        )}
      </Flex>

      <Img
        alt={heading || ''}
        display={{ base: 'none', lg: 'block' }}
        gridArea='image'
        image={image}
        {...imageProps}
      />

      {benefitsCards && (
        <CardsBlock
          gridArea='cards'
          list={benefitsCards}
          mt='md'
        />
      )}
    </DefaultContainer>
  );
};

export default AboutComponent;
