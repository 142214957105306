export const videoBlocksData = [
  {
    title: `Learn how to use\nreview requests`,
    steps: [
      'Set up automated review requests',
      'Monitor customer responses in real-time',
      'Boost your online reputation with ease',
      'Perfect for small business owners looking to grow their online presence',
    ],
    videoUrl: '2Lk-oqV7cJI',
  },
  {
    title: 'You’re in control of negative reviews',
    steps: [
      'Address issues before they escalate or go public',
      'Stay informed with notifications if anything goes wrong',
      'Use proven strategies to turn criticism into an opportunity',
      'Protect and strengthen your reputation effortlessly',
    ],
    videoUrl: 'oy-Gxr2yw48',
  },
];
