import { Flex, Heading } from '@chakra-ui/react';
import { DefaultContainer } from '@/shared/ui';
import { automationBenefits } from '../lib/automationBenefits';
import FeatureCard from './FeatureCard';

const AutomationBenefits = () => (
  <DefaultContainer
    alignItems='center'
    flexDir={{ base: 'column', lg: 'row' }}
    gap='xl'
    id='features'
    justifyContent={'space-between'}
  >
    <Heading
      textAlign={{ base: 'center', lg: 'start' }}
      w={{ base: 'full', lg: '40%' }}
    >
      How our automation solves these challenges
    </Heading>
    <Flex
      flexWrap='wrap'
      gap='xl'
      justifyContent='center'
      w={{ base: 'full', lg: '50%' }}
    >
      {automationBenefits.map((data) => (
        <FeatureCard
          key={data.heading}
          {...data}
        />
      ))}
    </Flex>
  </DefaultContainer>
);

export default AutomationBenefits;
