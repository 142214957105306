import { Flex, Heading, Text } from '@chakra-ui/react';
import { useState } from 'react';
import { Swiper as SwiperType } from 'swiper/types';
import { DefaultContainer } from '@/shared/ui';
import ReviewsRequestsCards from './ReviewsRequestsCards';
import ReviewsRequestsSlider from './ReviewsRequestsSlider';

const ReviewsRequestsBlock = () => {
  const [activeCardIndex, setActiveCardIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState<SwiperType>();

  const goToSlide = (index: number) => {
    setActiveCardIndex(index);
    swiperInstance?.slideToLoop(index);
  };

  return (
    <DefaultContainer gap='2xl'>
      <Flex
        flexDir='column'
        gap='sm'
        textAlign='center'
      >
        <Heading>Seamless Review & Referral Requests</Heading>
        <Text textAlign='center'>
          Our platform makes it easy to create, customize, and automate campaigns that reach your
          customers at just the right time.
        </Text>
      </Flex>
      <Flex
        alignItems='center'
        flexDir={{ base: 'column', md: 'row' }}
        gap={{ base: 'xl', lg: '4xl', xl: '10rem' }}
        justifyContent={{ base: 'space-between', lg: 'center' }}
      >
        <ReviewsRequestsCards
          activeCardIndex={activeCardIndex}
          onChangeCard={goToSlide}
        />
        <ReviewsRequestsSlider
          setActiveCardIndex={setActiveCardIndex}
          setSwiperInstance={setSwiperInstance}
          swiperInstance={swiperInstance}
        />
      </Flex>
    </DefaultContainer>
  );
};
export default ReviewsRequestsBlock;
