import { FoodIcon, HomeIcon, ToolsIcon } from '@/shared/ui';
import PerfumeIcon from 'src/shared/ui/icons/PerfumeIcon';

export const whoIsThisForList = [
  {
    Icon: HomeIcon,
    heading: 'Home Services & Trades',
    description: 'Plumbers, electricians, HVAC technicians, contractors, and more',
  },

  {
    Icon: PerfumeIcon,
    heading: 'Beauty & Wellness',
    description: 'Salons, nail bars, spas, barbershops, fitness studios',
  },
  {
    Icon: ToolsIcon,
    heading: 'Repair & Retail',
    description: 'Auto repair shops, electronics fix-it centers, small retail stores',
  },
  {
    Icon: FoodIcon,
    heading: 'Food & Hospitality',
    description: 'Cafés, bakeries, small restaurants, coffee shops',
  },
];
