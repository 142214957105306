import { Link } from '@chakra-ui/next-js';
import { Button, Heading, Highlight, Text } from '@chakra-ui/react';
import type { ButtonProps } from '@chakra-ui/react';
import {
  ActionButtons,
  Modal,
  deleteQuery,
  getLeadData,
  pushToDataLayer,
  setQuery,
} from '@repo/shared/shared';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  MAXIMUM_CHARACTERS_MSG,
  MINIMUM_CHARACTERS_MSG,
  REQUIRED_FIELD_MSG,
  brand,
} from '@/shared/constants';
import { usePageName } from '@/shared/hooks';
import { useGlobalState } from '@/shared/providers';
import { CheckboxField, EmailField, InputField, PhoneField, TextareaField } from '@/shared/ui';
import { useCreateAppLead } from '../model';

const CONSENT_TEXT = `By checking this box, I consent to receive marketing communications, via SMS and email from ${brand}`;

interface FormData {
  companyName: string;
  name: string;
  email: string;
  phone: string;
  description: string;
  isConsent: boolean;
}

const validation = {
  minLength: {
    value: 2,
    message: MINIMUM_CHARACTERS_MSG(),
  },
  maxLength: {
    value: 20,
    message: MAXIMUM_CHARACTERS_MSG(),
  },
};

interface IRequestModalProps extends ButtonProps {
  buttonText?: string;
  description?: string;
}

const RequestModal: FC<IRequestModalProps> = ({
  buttonText = 'Request access',
  description = '',
  ...rest
}) => {
  const queryName = 'req_access';
  const router = useRouter();
  const isOpen = router.query?.[queryName] === 'true';
  const { isMobile } = useGlobalState();
  const pageName = usePageName();

  const methods = useForm({
    reValidateMode: 'onBlur',
    mode: 'onBlur',
    defaultValues: {
      companyName: '',
      name: '',
      email: '',
      phone: '',
      description: '',
      isConsent: false,
    } satisfies FormData,
  });

  const onCloseModal = () => {
    deleteQuery(router, queryName);
  };

  const onOpen = () => {
    setQuery({ router, queryName, val: 'true' });
  };

  const { action: createAppLead, isLoading } = useCreateAppLead(onCloseModal);

  const onSubmit = ({ isConsent, ...formData }: FormData) => {
    pushToDataLayer('created_crm_lead', { from_page: pageName });
    const leadData = getLeadData();

    createAppLead({
      input: {
        ...leadData,
        ...formData,
        description: `${formData.description} ${description}`.trim(),
      },
    });
  };

  const isConsent = methods.watch('isConsent');

  return (
    <>
      <Button
        gridArea='button'
        variant={{ base: 'link', md: 'outline' }}
        onClick={onOpen}
        {...rest}
      >
        {buttonText}
      </Button>

      <Modal
        contentProps={{ overflow: 'scroll' }}
        isMobile={isMobile}
        isOpen={isOpen}
        onClose={onCloseModal}
      >
        <Heading
          size='lg'
          textAlign='center'
        >
          Access request
        </Heading>
        <FormProvider {...methods}>
          <form
            noValidate
            style={{ marginTop: '1rem' }}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <InputField
              isRequired
              label='Your name'
              name='name'
              placeholder='Enter your name'
              validationParameters={validation}
            />
            <EmailField
              validationParameters={{
                validate: (value: string, formData: FormData) => {
                  if (formData?.phone) {
                    return true;
                  }
                  return !!value || REQUIRED_FIELD_MSG('Email');
                },
              }}
              onChange={() => methods.trigger('phone')}
            />
            <PhoneField
              validationParameters={{
                validate: (value: string, formData: FormData) => {
                  if (formData?.email) {
                    return true;
                  }
                  return !!value || REQUIRED_FIELD_MSG('Phone');
                },
              }}
              onChange={() => methods.trigger('email')}
            />
            <InputField
              isRequired
              label='Company name'
              name='companyName'
              placeholder='Enter company name'
              validationParameters={validation}
            />
            <TextareaField
              label='Comments'
              name='description'
              placeholder='Any comments'
            />
            <CheckboxField
              Label={
                <Text>
                  <Highlight
                    query={[brand]}
                    styles={{ color: 'primary.pure' }}
                  >
                    {CONSENT_TEXT}
                  </Highlight>
                </Text>
              }
              name='isConsent'
            />
            <ActionButtons
              isSubmitting={isLoading}
              mt='0'
              negativeButtonProps={{ w: 'full' }}
              positiveButtonProps={{ type: 'submit', isDisabled: !isConsent, w: 'full' }}
              positiveButtonText='Send'
              onCancel={onCloseModal}
            />
            <Text
              fontSize='sm'
              mt='lg'
            >
              By submitting this form, you accept the terms of our{' '}
              <Link
                color='primary.pure'
                href='/terms'
                target='_blank'
              >
                User Agreement
              </Link>{' '}
              and acknowledge our{' '}
              <Link
                color='primary.pure'
                href='/privacy'
                target='_blank'
              >
                Privacy Policy
              </Link>
            </Text>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
};

export default RequestModal;
