import { Button, Flex, Heading } from '@chakra-ui/react';
import { RadioButtonField, RadioFieldMultiple } from '@repo/shared/shared';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputField } from '@/shared/ui';
import { IQuizStepData } from '../lib/quizStepsData';

const optionsFields = {
  single: RadioButtonField,
  multiple: RadioFieldMultiple,
};

interface IQuizFormStepProps extends IQuizStepData {
  onNext: () => void;
}

const QuizFormStep: FC<IQuizFormStepProps> = ({
  heading,
  onNext,
  options,
  input,
  buttonText = 'Next',
  buttonType = 'button',
}) => {
  const OptionsField = !!options && optionsFields[options.type];
  const { control, watch } = useFormContext();

  const answer = options?.name && watch(options.name);
  const inputAnswer = input?.name && watch(input.name);

  let isDisabled = false;

  if (input?.requiredAnswer) {
    isDisabled = !answer || (input.requiredAnswer === answer && !inputAnswer);
  } else if (input) {
    isDisabled = !inputAnswer && !answer?.length;
  } else {
    isDisabled = !answer?.length;
  }

  return (
    <Flex
      alignSelf='center'
      flexDir='column'
      gap='xl'
      justifyContent='center'
      px='1'
      w={{ base: 'full', lg: 'container.md' }}
    >
      <Heading
        as='h4'
        fontSize='2xl'
        fontWeight='semibold'
        whiteSpace='pre-wrap'
      >
        {heading}
      </Heading>

      <OptionsField
        containerProps={{
          flexWrap: 'wrap',
          flexDir: 'row',
          justifyContent: 'space-between',
          w: 'full',
        }}
        control={control}
        name={options.name}
        options={options.items}
        w={{ base: 'full', md: '49%' }}
        {...options.styles}
      />

      {input && (
        <InputField
          bg='bg.pure'
          name={input.name}
          placeholder={input.placeholder}
        />
      )}

      <Button
        alignSelf='center'
        bg='bg.pure'
        isDisabled={isDisabled}
        type={buttonType}
        variant='outline'
        w='3xs'
        onClick={onNext}
      >
        {buttonText}
      </Button>
    </Flex>
  );
};
export default QuizFormStep;
