import { Flex, Heading, Highlight, Text } from '@chakra-ui/react';
import type { ContainerProps } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { DefaultContainer } from '@/shared/ui';
import { getStartedBlockData } from '../lib/getStartedBlockData';

interface IActionBlockProps extends ContainerProps {
  heading: string;
  description: string;
  buttonText?: string;
  href?: string;
  button?: ReactNode;
  highlight?: string[];
}
const ActionBlock: FC<IActionBlockProps> = ({
  heading,
  description,
  button,
  highlight = [''],
  ...props
}) => (
  <Flex
    alignItems='center'
    borderRadius='xl'
    flexDir='column'
    gap='xl'
    px={{ base: 6, md: 20 }}
    py={{ base: 10, md: 20 }}
    {...props}
  >
    <Heading textAlign='center'>{heading}</Heading>
    <Text
      fontSize='lg'
      textAlign='center'
      w={{ base: 'full', md: 'container.sm' }}
    >
      <Highlight
        query={highlight}
        styles={{ color: 'primary.pure', fontWeight: 'medium' }}
      >
        {description}
      </Highlight>
    </Text>
    {button && button}
  </Flex>
);

const GetStartedBlock = () => {
  return (
    <DefaultContainer py='0'>
      {getStartedBlockData.map((data, index) => (
        <ActionBlock
          key={data.heading}
          bg={(index + 1) % 2 === 0 ? 'bg.pure' : 'bg.light'}
          {...data}
        />
      ))}
    </DefaultContainer>
  );
};

export default GetStartedBlock;
