import { Card, Flex, Heading, ListItem, OrderedList } from '@chakra-ui/react';
import { FC } from 'react';
import { reviewsRequestsCards } from '../lib/reviewsRequestsCards';

interface IReviewsRequestsCardsProps {
  activeCardIndex: number;
  onChangeCard: (index: number) => void;
}

const ReviewsRequestsCards: FC<IReviewsRequestsCardsProps> = ({
  activeCardIndex,
  onChangeCard,
}) => (
  <Flex
    flexDir={{ base: 'row', md: 'column' }}
    flexWrap='wrap'
    gap='md'
    justifyContent={{ base: 'start', lg: 'start' }}
  >
    {reviewsRequestsCards.map(({ heading, steps }, index) => (
      <Card
        cursor='pointer'
        variant='outline'
        borderColor={index === activeCardIndex ? 'primary.pure' : 'border.pure'}
        key={heading}
        p='sm'
        w='full'
        onClick={() => onChangeCard(index)}
      >
        <Heading
          as='h3'
          size='md'
        >
          {heading}
        </Heading>

        <OrderedList
          ml='lg'
          mt='xs'
        >
          {steps.map((step) => (
            <ListItem key={step}>{step} </ListItem>
          ))}
        </OrderedList>
      </Card>
    ))}
  </Flex>
);

export default ReviewsRequestsCards;
