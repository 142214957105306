export const questions = [
  {
    question: '1. What exactly does your review automation platform do?',
    answer:
      'Our platform automates the process of requesting and collecting customer reviews. It sends personalized emails and SMS messages to your customers, prompting them to share feedback on popular review sites like Google and Facebook. You can also track, manage, and respond to reviews from one simple dashboard.',
  },
  {
    question: '2. How will this help my business?',
    answer:
      'By automating review requests, you’ll save time, gather more positive reviews, and improve your online reputation - all of which attract new customers. Additionally, features like negative review notifications ensure you stay on top of potential issues before they escalate.',
  },
  {
    question: '3. Is it difficult to set up?',
    answer:
      'Not at all! We designed our platform to be simple and straightforward. You can get started within minutes. Plus, we offer free personal onboarding where our team walks you through every step, ensuring your automation is tailored to your business needs.',
  },
  {
    question: '4. Can I customize the messages sent to customers?',
    answer:
      'Absolutely! You can personalize the review request SMS and email messaging with your branding, unique tone, and any other details that make sense for your audience. You can also add pre-review questions to gather specific feedback or information.',
  },
  {
    question: '5. What if a customer leaves a negative review?',
    answer:
      'Our platform instantly notifies you whenever a negative review comes in, giving you a chance to follow up directly with the customer. This proactive approach helps you address issues quickly and can turn unhappy customers into loyal fans.',
  },
  {
    question: '6. Do you offer referral automation, too?',
    answer:
      'Yes! We don’t stop at review requests. You can automate customer referral requests and follow-ups as well, helping you leverage the power of word-of-mouth marketing to grow your customer base.',
  },
  {
    question: '7. How does the microsite work?',
    answer:
      'We provide a customizable microsite that showcases your best reviews, encouraging new customers to see the positive experiences others have had. This microsite can also collect leads through a built-in lead capture form, helping you convert visitors into paying customers.',
  },
  {
    question: '8. What kind of analytics do you provide?',
    answer:
      'With our campaign insights and analytics, you can monitor open rates, conversion rates. You’ll also see how your star ratings change over time, how many new reviews you’re collecting, and more - so you can optimize your review strategy.',
  },
  {
    question: '9. Is there a free trial?',
    answer:
      'Yes, we offer a 14-day free trial with no credit card required. There are also no long-term contracts, so you can cancel anytime if you’re not satisfied (though we’re confident you’ll love the results!).',
  },
  {
    question: '10. How do you handle pricing?',
    answer:
      'We pride ourselves on transparent, all-inclusive pricing. You’ll never see hidden fees or surprise charges. What you see is what you pay. Contact our team or sign up for the free trial to see which plan best fits your business.',
  },
  {
    question: '11. Can I manually send review requests if needed?',
    answer:
      'Yes. While our platform automates most of the process, you can still manually send review requests to specific customers whenever you like - perfect for in-person interactions or special follow-ups.',
  },
  {
    question: '12. How can I contact your support team?',
    answer:
      'We offer U.S.-based support via call, email, and live chat. During onboarding, we’ll introduce you to our support resources so you’ll always know how to get help if you need it.',
  },
];
