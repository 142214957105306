export interface IQuizForm {
  locationsCount: string;
  monthlyCustomers: string;
  reviewPlatforms: string[];
  otherReviewPlatforms: string;
  crmPlatforms: string[];
  otherCrmPlatforms: string;
  interestedSolutions: string[];
  otherInterestedSolutions: string;
  isUseReviewManagers: string;
  otherReviewManagers: string;
}

const generateQuizResponse = (quizAnswers: Partial<IQuizForm>) => {
  const currentSoftware = quizAnswers.crmPlatforms || quizAnswers.reviewPlatforms || [];
  const locationCount = quizAnswers.locationsCount || '';
  const interestedSolutions = quizAnswers.interestedSolutions || [];

  let integrationPart = '';
  let locationPart = '';
  let comingSoonPart = '';

  if (currentSoftware.length > 0) {
    const softwareList = [...currentSoftware];
    integrationPart = `Our platform integrates with ${softwareList.join(', ')}`;
  } else {
    integrationPart = 'Our platform allows you to send manual requests';
  }

  if (locationCount) {
    if (locationCount === '1') {
      locationPart = 'for your single location';
    } else {
      locationPart = 'across all your locations';
    }
  }

  const comingSoonFeatures = [
    'Customer Surveys (NPS)',
    'Listings Management',
    'Competitor Review Monitoring',
  ];

  const selectedComingSoon = interestedSolutions.filter((solution) =>
    comingSoonFeatures.includes(solution),
  );

  if (selectedComingSoon.length > 0) {
    comingSoonPart = `We're also excited to announce that ${selectedComingSoon.join(' and ')} will be coming soon to our platform`;
  }

  let response = `${integrationPart} and automates review requests ${locationPart}.`;

  if (comingSoonPart) {
    response += ` ${comingSoonPart}.`;
  }

  response +=
    '\nStart your free trial now to see everything in action with real-time support and onboarding!';

  return response;
};

export default generateQuizResponse;
