import { Box } from '@chakra-ui/react';
import { Dispatch, FC, SetStateAction } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper/types';
import { Image } from '@/shared/ui';
import slide1 from '/public/main-page/slider-1.png';
import slide2 from '/public/main-page/slider-2.png';
import slide3 from '/public/main-page/slider-3.png';
import slide4 from '/public/main-page/slider-4.webp';

const slidesImages = [
  { image: slide1, alt: 'Review request campaign' },
  { image: slide2, alt: 'Pre-review survey' },
  { image: slide3, alt: 'Review platforms' },
  { image: slide4, alt: 'Crm integration' },
];

interface IReviewsRequestsSliderProps {
  setSwiperInstance: Dispatch<SetStateAction<SwiperType | undefined>>;
  swiperInstance?: SwiperType;
  setActiveCardIndex: (index: number) => void;
}

const ReviewsRequestsSlider: FC<IReviewsRequestsSliderProps> = ({
  setSwiperInstance,
  swiperInstance,
  setActiveCardIndex,
}) => (
  <Box
    maxW={{ base: 'full', md: '50%', xl: '32rem' }}
    sx={{
      '.swiper': {
        height: '100%',
        pb: 'sm',
        '.swiper-wrapper': { alignItems: 'center' },
        '.swiper-slide': {
          display: 'flex',
          alignItems: 'center',
        },
        '.swiper-pagination': {
          position: 'absolute',
          bottom: -1,
        },
        '.swiper-pagination-bullet-active': {
          bg: 'primary.pure',
        },
      },
    }}
  >
    <Swiper
      autoplay={{
        delay: 2000,
        disableOnInteraction: false,
      }}
      className='swiper'
      grabCursor={true}
      loop={true}
      modules={[Pagination, Autoplay]}
      pagination={{
        clickable: true,
      }}
      slidesPerView={1}
      spaceBetween={20}
      speed={1000}
      onSlideChange={({ realIndex }) => setActiveCardIndex(realIndex)}
      onSwiper={setSwiperInstance}
    >
      {slidesImages.map(({ image, alt }, index) => (
        <SwiperSlide key={index}>
          {({ isActive, isNext }) => (
            <Box>
              <Image
                alt={alt}
                aspectRatio={image.width / image.height}
                borderRadius='xl'
                isEager={isActive || isNext}
                src={image}
                width={swiperInstance?.width || '30rem'}
              />
            </Box>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  </Box>
);

export default ReviewsRequestsSlider;
