import { RedirectToAppButton } from '@/widgets/RedirectToAppButton';
import { RequestAccessModal } from '@/widgets/RequestAccessModal';

export const getStartedBlockData = [
  {
    heading: 'Ready to Get Started?',
    description:
      'Sign up for a 14-day free trial - no credit card required, no hidden fees. Experience how easy it is to automate your review and referral requests, and see firsthand how our platform boosts your online reputation',
    highlight: ['14-day free trial'],
    button: (
      <RedirectToAppButton
        buttonText='Start free trial'
        dataLayer={{ data: { redirected_from: 'main_page_get_started_block' } }}
        pageName='signup'
        size='md'
      />
    ),
  },
  {
    heading: 'Prefer a Hands-On Setup?',
    description:
      'Book a free onboarding call with our team. We’ll walk you through the entire setup process, customize integrations, and ensure you’re ready to collect reviews and referrals from day one.',
    button: (
      <RequestAccessModal
        buttonText='Schedule a call'
        variant='outline'
      />
    ),
    highlight: ['free onboarding call'],
  },
];
